
import { Component, Prop, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { uniq } from 'lodash';

// types
import { Region } from '@/models/stores/regions';
import { Brand } from '@/models/stores/brands';
import { StoreUser } from '@/api/contracts/storeUser';
import { UserStore } from '@/models/users/userStore';

interface RegionSelect {
  text: string;
  value: Region;
}
interface StoreSelect {
  value: guid;
  text: string;
  state: Region;
  brand: Brand;
  isParticipatingInAutomatedProgram: boolean;
}

@Component({
  components: {}
})
export default class MobStoreSwitcher extends LoggedInComponentBase {
  @Prop({ default: false }) isUpcomingPosts!: boolean;
  public isSheetOpen: boolean = false;
  public storesLoading: boolean = false;

  public get isMSO(): boolean {
    return this.userModule.isMultiStoreOwner;
  }

  public get selectedBrand() {
    return this.storesModule.currentBrand;
  }

  public get currentStore(): UserStore {
    return this.userModule.currentStore;
  }

  public get currentUser(): StoreUser | null {
    return this.userModule.currentUser;
  }

  public get isViewingAllRegions(): boolean {
    return this.storesModule.currentRegion === Region.ALL;
  }

  public get userStores(): Array<StoreSelect> {
    if (!this.currentUser) return [];

    /**
     * The Vuetify `select` component requires `text` and `value` properties.
     */
    return this.storesModule.userStoresInBrandAndRegion.map(store => {
      return {
        text: store.title,
        value: store.id,
        state: store.state,
        brand: store.brand,
        isParticipatingInAutomatedProgram:
          store.isParticipatingInAutomatedProgram
      };
    });
  }

  public get regionAndCountStr(): string {
    const region = this.storesModule.currentRegion;

    if (this.userModule.isViewingSingleStore) {
      // sso
      if (this.isUpcomingPosts) {
        return `Upcoming Posts in ${this.currentStore.title}`;
      } else {
        // sso asset-library
        return `${this.currentStore.title}`;
      }
    }

    // mso
    const storeCount = this.storesModule.regionalStoreCount.get(
      region
    ) as number;
    const storeStr = storeCount > 1 ? 'stores' : 'store';

    if (this.isUpcomingPosts) {
      return `Upcoming Posts in (${storeCount}) ${storeStr}`;
    }

    return `(${storeCount}) ${storeStr}`;
  }

  public onStoreSwitcherClick() {
    if (this.isMSO) this.isSheetOpen = true;
  }

  //
  // regions
  //
  public get regions(): RegionSelect[] {
    const regionsFilteredBySelectedBrand = uniq(
      this.userModule
        .currentUser!.stores.filter(store => {
          if (this.selectedBrand === null) return true;
          return this.selectedBrand.name === store.brand.name;
        })
        .map(store => store.state)
    );

    if (regionsFilteredBySelectedBrand.length > 1) {
      regionsFilteredBySelectedBrand.push(Region.ALL);
      const region =
        regionsFilteredBySelectedBrand[
          regionsFilteredBySelectedBrand.length - 1
        ];
    } else {
      const region = regionsFilteredBySelectedBrand[0];
      if (region !== this.selectedRegion.value) {
        this.selectedRegion = {
          text: this.getRegionSelectionItemText(region),
          value: region
        };
      }
    }

    return regionsFilteredBySelectedBrand.map(region => ({
      text: this.getRegionSelectionItemText(region),
      value: region
    }));
  }

  public get selectedRegion(): RegionSelect {
    return {
      text: this.getRegionSelectionItemText(this.storesModule.currentRegion),
      value: this.storesModule.currentRegion
    };
  }

  public set selectedRegion(region: RegionSelect) {
    this.storesLoading = true;
    this.storesModule.regionChanged(region.value).then(() => {
      this.checkSocialChannelsAuthorised();
      this.storesLoading = false;
    });
  }
  //
  // /regions
  //

  //
  // stores
  //

  public get selectedStore(): StoreSelect {
    return {
      value: this.currentStore.id,
      text: this.currentStore.title,
      state: this.currentStore.state,
      brand: this.currentStore.brand,
      isParticipatingInAutomatedProgram: this.currentStore
        .isParticipatingInAutomatedProgram
    };
  }

  public set selectedStore(store: StoreSelect) {
    this.userModule.setCurrentStore({
      id: store.value,
      title: store.text,
      state: store.state,
      brand: store.brand,
      isParticipatingInAutomatedProgram: store.isParticipatingInAutomatedProgram
    });
    this.storesModule.currentStoreSet(true);
    this.checkSocialChannelsAuthorised();
  }

  //
  // /stores
  //

  public getRegionSelectionItemText(region: Region): string {
    const storeCount = this.storesModule.regionalStoreCount.get(
      region
    ) as number;
    return `${region} - ${storeCount} ${storeCount > 1 ? 'stores' : 'store'}`;
  }

  @Watch('selectedRegion')
  private onRegionSelected(newVal: RegionSelect, oldVal: RegionSelect): void {
    if (oldVal.value !== newVal.value) {
      this.storesModule.currentStoreSet(false);
      this.storesModule.regionChanged(newVal.value);
    }
  }
}
