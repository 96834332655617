
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class PostPreviewEmpty extends LoggedInComponentBase {
  @Prop({ default: '100%', type: [String, Number] }) readonly height!:
    | string
    | number;
  @Prop({ default: '100%', type: [String, Number] }) readonly width!:
    | string
    | number;
}
