
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { PostListItem } from '@/models/posts/postListItem';
import { format, isPast, parseISO } from 'date-fns';
import { PostsModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import { DataTableItem, States } from '@/models/posts/postModal';
import { EventTypesLabels, EventTypes, MsoPostTableHeaders } from '@/constants';
import { getPostChannels } from '@/services/eventFactory';
import StoresTable from '@/components/modals/postModals/storesTable.vue';
import PublishedPostLinksTable from '@/components/modals/postModals/publishedPostLinksTable.vue';
import { SocialChannel } from '@/models/posts/socialChannels';
import { DataTableHeader } from 'vuetify';
import cloudinaryImageResize from '@/helpers/cloudinary-image-resize';

interface StringDictionary {
  [key: string]: string;
}

const postsModule = getModule(PostsModule);
const eventNames: StringDictionary = {
  recommendedPosts: 'Recommended Post',
  storePosts: 'Store Post',
  draftPosts: 'Draft Post',
  automatedPosts: 'Automated Post'
};

@Component({
  components: { StoresTable, PublishedPostLinksTable },
  methods: { getPostChannels }
})
export default class PostDetailSidebar extends LoggedInComponentBase {
  @PropSync('showDrawer', { default: false, required: true }) drawer!: boolean;
  @Prop({ required: true }) post!: PostListItem;

  public showDeletePostDialog: boolean = false;
  public imageLoaded: boolean = false;
  public assetKey: number = 0;
  public tableHeaders: DataTableHeader[] = MsoPostTableHeaders;

  public get msoPostTableItems(): DataTableItem[] {
    let items: DataTableItem[] = [];
    if (
      this.post.isMsoPost &&
      this.userModule.isMultiStoreOwner &&
      this.post.msoPostStores
    ) {
      items = this.post.msoPostStores!.map(store => ({
        id: store.storeId,
        name: store.name,
        region: store.region,
        facebook: {
          disabled: true,
          value: store.postedChannels.some(
            (channel: any) => channel.name === SocialChannel.Facebook
          )
        },
        instagram: {
          disabled: true,
          value: store.postedChannels.some(
            (channel: any) => channel.name === SocialChannel.Instagram
          )
        }
      }));
    }
    return items;
  }

  public get msoPostTableTitle(): string {
    const numStoresPostedTo = this.post.msoPostStores?.length ?? 0;
    return `This post is saved to ${numStoresPostedTo} stores.`;
  }

  public get isRenderedAsMsoPost(): boolean {
    return !!this.post.isMsoPost && !this.userModule.isViewingSingleStore;
  }

  public get isRenderedAsSsoPost(): boolean {
    return !this.post.isMsoPost && this.userModule.isMultiStoreOwner;
  }

  public get isStoreChannelsTableVisible(): boolean {
    return this.isRenderedAsMsoPost && !this.isInThePast && !this.isDraftPost;
  }

  public get isPublishedPostLinksVisible(): boolean {
    return this.isInThePast && !this.isDraftPost;
  }

  public get storeName(): string {
    if (!this.post.msoPostStores) return 'the current store';
    const store = this.post.msoPostStores[0];
    return store.storeId === this.currentStore.id
      ? 'the current store'
      : `${store.name}, ${store.region}`;
  }

  public get isInThePast(): boolean {
    return isPast(parseISO(this.post?.publishedDateTime));
  }

  public get isAlsoPostedToOtherStores(): boolean {
    return (
      !!this.post.isMsoPost &&
      (!this.userModule.isMultiStoreOwner ||
        this.userModule.isViewingSingleStore)
    );
  }

  public get editOrDeleteButtonDisabled() {
    return this.isInThePast || this.isAlsoPostedToOtherStores;
  }

  public get isFacebookPost() {
    // @ts-ignore
    return this.post?.availableChannels?.includes('Facebook');
  }

  public get isInstagramPost() {
    // @ts-ignore
    return this.post?.availableChannels?.includes('Instagram');
  }

  public get allowCreatePost(): boolean {
    const match = ['recommendedPosts', 'draftPosts'];
    return match.indexOf(this.post?.postType ?? '') >= 0;
  }

  public get imageUrl(): string {
    const blobUrl = this.post?.asset?.blobUrl;
    return blobUrl ? cloudinaryImageResize(blobUrl, 480) : '';
  }

  public get isVideo(): boolean {
    return !!this.post.asset?.isVideoAsset;
  }

  public get videoSrc(): string {
    return this.post.asset?.blobUrl || '';
  }

  public get isAutomatedPost() {
    return this.post?.postType === EventTypesLabels.AutomatedPosts;
  }

  public get isDraftPost() {
    return this.post?.postType === EventTypesLabels.DraftPosts;
  }

  public get isRecommendedPost() {
    return this.post?.postType === EventTypesLabels.RecommendedPosts;
  }

  public get isStorePost() {
    return this.post?.postType === EventTypesLabels.StorePosts;
  }

  public get labelColor() {
    return EventTypes.find(item => {
      return item.name === 'recommendedPosts';
    })?.colour;
  }

  public storePostLabel(publishedDateTime: Date): string {
    return isPast(new Date(publishedDateTime)) ? 'Posted' : 'Scheduled';
  }

  public get postTitle() {
    if (this.post) {
      const {
        publishedDateTime,
        suggestedPublishDateTime,
        postType
      } = this.post;

      const label = isPast(new Date(publishedDateTime))
        ? ' - Posted'
        : ' - Scheduled';

      const date = format(
        postType === 'storePosts'
          ? new Date(publishedDateTime)
          : new Date(suggestedPublishDateTime),
        'MMM d'
      );
      return `${date} - ${eventNames[postType]}${(postType === 'storePosts' &&
        label) ||
        ''}`;
    } else return 'Unknown';
  }

  public get showAudiencesProductsTitle() {
    return (
      this.post?.postType === EventTypesLabels.RecommendedPosts &&
      this.post?.asset &&
      ((this.post?.asset.audiences && this.post?.asset.audiences.length > 0) ||
        (this.post?.asset.productTypes &&
          this.post?.asset.productTypes.length > 0))
    );
  }

  public closeDrawer() {
    this.$emit('close-drawer');
  }

  private async deletePostForStore(
    storeId: guid,
    postId?: guid,
    msoPostId?: guid
  ): Promise<unknown> {
    return await postsModule.deletePost({ storeId, postId, msoPostId });
  }

  public async deletePost() {
    if (!this.post) return;

    let response;
    if (this.isRenderedAsMsoPost) {
      response = await Promise.allSettled(
        this.post.msoPostStores!.map(store =>
          this.deletePostForStore(store.storeId, undefined, this.post.msoGuid)
        )
      );
    } else {
      const storeId =
        this.currentStore.id || this.post.msoPostStores![0].storeId;
      response = await this.deletePostForStore(storeId, this.post.id);
    }
    if (response) {
      this.showDeletePostDialog = false;
      this.drawer = false;
      // // Needed a slight delay otherwise refreshing the calendar still pulled down the deleted post.
      setTimeout(() => {
        this.$emit('post-updated');
      }, 500);
    } else {
      console.error('There was an error...');
    }
  }

  private async unpublishPostForStore(payload: {
    storeId: guid;
    postId?: guid;
    msoPostId?: guid;
    channels?: SocialChannel[];
  }): Promise<unknown> {
    return await postsModule.unpublishPost(payload);
  }

  public async unpublishPost() {
    // switch to draft to remove later
    if (!this.post) return;
    let response;
    if (this.post.isMsoPost && !this.userModule.isViewingSingleStore) {
      response = await Promise.allSettled(
        this.post.msoPostStores!.map(store =>
          this.unpublishPostForStore({
            storeId: store.storeId,
            msoPostId: this.post.msoGuid,
            channels: store.postedChannels.map((store: any) => store.name)
          })
        )
      );
    } else {
      const storeId =
        this.currentStore.id || this.post.msoPostStores![0].storeId;
      response = await this.unpublishPostForStore({
        storeId,
        postId: this.post.id,
        channels: this.post.channels
      });
    }
    if (response) {
      // // Needed a slight delay otherwise refreshing the calendar still pulled down the updated post.
      setTimeout(() => {
        this.$emit('post-updated');
      }, 500);
    } else {
      console.error('There was an error...');
    }
  }

  public createNewPost() {
    this.closeDrawer();

    // recommended posts now use 'create-post' flow
    if (this.isRecommendedPost) {
      this.createModule.setSelectedAsset(this.post.asset);
      this.createPostModule.setPostTemplate(this.post);
      this.$router.push({ path: '/create-post' });
      return;
    }

    this.assetModalModule.setSelectedAsset(this.post?.asset ?? null);
    this.postModalModule.setState(States.NEW_POST);
    this.postModalModule.setId(this.post?.id ?? null);
    this.postModalModule.setTitle(this.post?.title ?? '');
    this.postModalModule.setBody(this.post?.copy ?? '');
    this.postModalModule.setAvailableChannels(
      this.post?.availableChannels ?? []
    );

    if (this.isDraftPost) {
      const suggestedDate = new Date(this.post?.suggestedPublishDateTime ?? '');
      this.postModalModule.setFullDate(suggestedDate);
      this.postModalModule.setDate(format(suggestedDate, 'yyyy-MM-dd'));
      this.postModalModule.setTime(
        suggestedDate.toLocaleTimeString('en-AU', { hour12: false })
      );

      isPast(suggestedDate)
        ? this.postModalModule.setImmediate(true)
        : this.postModalModule.setImmediate(false);
    }

    this.postModalModule.setVisible(true);
  }

  public editPost() {
    this.closeDrawer();

    const postDate =
      this.isDraftPost || this.isRecommendedPost
        ? new Date(this.post?.suggestedPublishDateTime ?? '')
        : new Date(this.post?.publishedDateTime ?? '');

    this.assetModalModule.setSelectedAsset(this.post?.asset ?? null);
    this.postModalModule.setState(States.EXISTING_POST);
    this.postModalModule.setId(this.post?.id ?? null);
    this.postModalModule.setTitle(this.post?.title ?? '');
    this.postModalModule.setBody(this.post?.copy ?? '');
    this.postModalModule.setIsDraft(this.isDraftPost);
    this.postModalModule.setChannels(this.post?.channels || []);
    if (this.post.isMsoPost && !this.userModule.isViewingSingleStore) {
      this.postModalModule.setMsoPostId(this.post.msoGuid!);
      this.postModalModule.setMsoPostStoresDetails(this.post.msoPostStores!);
    }

    if (this.post) {
      this.postModalModule.setEditedPost(this.post);
    }

    if (isPast(postDate)) {
      this.postModalModule.setImmediate(true);
    } else {
      this.postModalModule.setImmediate(false);
      this.postModalModule.setDate(format(postDate, 'yyyy-MM-dd'));
      this.postModalModule.setTime(format(postDate, 'H:mm'));
    }

    this.postModalModule.setVisible(true);
  }

  @Watch('drawer')
  onDrawerOpenClose(flag: boolean) {
    this.imageLoaded = false;
    if (flag) {
      this.assetKey++;
    }
  }
}
