
import { Component, Prop } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';

const timeouts = {
  success: '4000',
  error: '10000'
};

@Component
export default class Notification extends LoggedInComponentBase {
  /**
   * Use these optional props to override default timeouts.
   */
  @Prop() successTimeout: string | undefined;
  @Prop() errorTimeout: string | undefined;

  public show: boolean = false;
  public colour: string = 'success';
  public timeout: string = timeouts.success;
  public message: string = '';

  public hide() {
    this.show = false;
  }

  public resetNotification() {
    this.timeout = this.successTimeout ?? timeouts.success;
    this.message = '';
    this.colour = 'success';
  }

  public showError(message: string): void {
    this.timeout = this.errorTimeout ?? timeouts.error;
    this.message = message;
    this.colour = 'error';
    this.show = true;
  }

  public showSuccess(message: string): void {
    this.resetNotification();
    this.message = message;
    this.show = true;
  }
}
