
import { Component, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import {
  format,
  isToday,
  isBefore,
  startOfToday,
  startOfWeek,
  addWeeks,
  addDays,
  subWeeks,
  getDate,
  isFirstDayOfMonth,
  startOfYear,
} from 'date-fns';
import Swiper, { Navigation } from 'swiper';
import PostPreview from '@/components/cards/postPreview.vue';
import PostPreviewEmpty from '@/components/cards/postPreviewEmpty.vue';
import { HomeServiceClient } from '@/api/clients/homeServiceClient';
import EventFactory from '@/services/eventFactory';

// types
import { EventTypesLabels } from '@/constants';
import { PostListItem } from '@/models/posts/postListItem';
import { HomeResponse } from '@/api/contracts/home/homeResponse';
import { ALLSTORES } from '@/constants';

import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';

type PostDate = {
  dateNo: number;
  date: Date;
  dateYear: string;
  dateMonth: string;
  isToday: boolean;
};

const client = new HomeServiceClient();

@Component({
  components: {
    PostPreview,
    PostPreviewEmpty,
  },
  methods: {
    format,
  },
})
export default class DailyPosts extends LoggedInComponentBase {
  public posts: PostListItem[] = [];

  public swiper: Swiper | null = null;

  public today = startOfToday();
  public todaysWeek = startOfWeek(this.today, { weekStartsOn: 1 });

  public startDay: Date | null = null;
  public endDay: Date | null = null;

  public dateChunks: Array<PostDate>[] = [];
  public selectedDate: number = this.today.getTime();
  public slideWeekIndexes: Array<number> = [-1, 0, 1];

  public get slides() {
    return this.dateChunks;
  }

  public updateWeekRange() {
    this.startDay = this.dateChunks[1][0].date;
    this.endDay = addDays(this.startDay, 6);
  }

  private initSwiper() {
    this.swiper = new Swiper(this.$refs.swiper as HTMLElement, {
      modules: [Navigation],
      slidesPerView: 'auto',
      threshold: 10,
      initialSlide: 1,
      resistanceRatio: 0, // disable bounce
      runCallbacksOnInit: false,
      navigation: {
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev',
      },
      on: {
        slideNextTransitionEnd: (swiper) => {
          this.slideWeekIndexes.shift();
          this.dateChunks.shift();

          // replace [0] at [2]
          this.slideWeekIndexes.push(this.slideWeekIndexes[1] + 1);
          this.dateChunks.push(this.getDateChunk(this.slideWeekIndexes[2]));
        },
        slidePrevTransitionEnd: (swiper) => {
          this.dateChunks.pop();
          this.slideWeekIndexes.pop();

          // replace [2] at [0]
          this.slideWeekIndexes.unshift(this.slideWeekIndexes[0] - 1);
          this.dateChunks.unshift(this.getDateChunk(this.slideWeekIndexes[0]));
        },
        slideChangeTransitionEnd: (swiper) => {
          this.$nextTick(() => {
            this.updateWeekRange();
            swiper.update();
            swiper.slideTo(1, 0, false);
          });
        },
      },
    });
  }

  private getDateChunk(weeks: number): PostDate[] {
    const datesInWeek: PostDate[] = [];

    let newWeek;
    if (weeks < 0) {
      newWeek = subWeeks(this.todaysWeek, Math.abs(weeks));
    } else if (weeks === 0) {
      newWeek = this.todaysWeek;
    } else {
      // > 0
      newWeek = addWeeks(this.todaysWeek, Math.abs(weeks));
    }

    for (let i = 0; i < 7; i++) {
      const thisDay = addDays(newWeek, i);

      datesInWeek.push({
        dateNo: getDate(thisDay),
        date: thisDay,
        dateYear:
          startOfYear(thisDay).getTime() === thisDay.getTime()
            ? format(thisDay, 'y')
            : '',
        dateMonth: isFirstDayOfMonth(thisDay) ? format(thisDay, 'LLL') : '',
        isToday: thisDay.getTime() === this.today.getTime(),
      });
    }

    return datesInWeek;
  }

  async created() {
    this.dateChunks.push(this.getDateChunk(this.slideWeekIndexes[0]));
    this.dateChunks.push(this.getDateChunk(this.slideWeekIndexes[1]));
    this.dateChunks.push(this.getDateChunk(this.slideWeekIndexes[2]));
    this.updateWeekRange();

    await this.getPosts();
  }

  mounted() {
    this.initSwiper();
  }

  private async getPosts() {
    const homeResponse = await this.getHomeDataByDate();
    const homeData = homeResponse.data;
    // todo: check if posts can be a prop like weeklyPosts
    this.posts = homeData.posts;
    this.posts.forEach((p) => {
      EventFactory.setPostType(p);
      EventFactory.setPostAvailability(p);
    });
  }

  private async getHomeDataByDate(): Promise<HomeResponse> {
    const storeId =
      this.userModule.currentStore.title === ALLSTORES
        ? undefined
        : this.userModule.currentStore.id;
    const region = this.storesModule.currentRegion;
    const response = await client.getHomeEvents(
      region,
      storeId,
      format(new Date(this.selectedDate), 'Y-M-d')
    );
    return response;
  }

  public createNewPost() {
    const dateClicked: Date = new Date(this.selectedDate);

    this.createPostModule.setScheduledDate(format(dateClicked, 'yyyy-MM-dd'));
    this.createPostModule.setScheduledTime(format(dateClicked, 'K:mm'));

    if (!isToday(dateClicked)) {
      this.createPostModule.setIsScheduledImmediately(false);
    }

    this.$router.push({ path: '/create-post' });
  }

  public showPost(post: PostListItem) {
    /**
     * We need to pass an object that mimics the object passed when an event on the actual calendar
     * is clicked, so we can call the same method whether we click on one of those events or on an 'event'
     * in this component.
     */
    const postType = post.postType;
    this.$emit('show-post-detail', {
      nativeEvent: null,
      event: { ...post, eventType: postType },
    });
  }

  public isBeforeToday(date: Date): boolean {
    return isBefore(date, startOfToday());
  }

  public removeUsedRecommendedPosts(posts: PostListItem[]): PostListItem[] {
    const result: PostListItem[] = [];
    posts.forEach((post) => {
      if (post.postType === EventTypesLabels.RecommendedPosts) {
        const recommendedPostId = post.id;
        if (
          !posts.some((x) => {
            return x.usedTemplateId === recommendedPostId;
          })
        ) {
          result.push(post);
        }
      } else if (!result.some((result) => result.id === post.id)) {
        result.push(post);
      }
    });
    return result;
  }

  @Watch('selectedDate')
  onSelectedDateChange() {
    this.getPosts();
  }

  @Watch('currentStore')
  public onStoreChanged() {
    this.getPosts();
  }
}
