var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"transition-swing",attrs:{"elevation":hover ? 6 : 2,"color":"white lighten-5"}},[_c('v-card-subtitle',{staticClass:"black--text caption pa-3"},[_vm._v(" "+_vm._s(_vm.campaign.title)+" ")]),_c('v-img',{attrs:{"lazy-src":_vm.imageUrl,"src":_vm.imageUrl,"max-width":"100%","aspect-ratio":"1"}},[_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"color":"white","opacity":".7","z-index":"2","absolute":""}},[_c('v-btn',{staticClass:"mx-2",attrs:{"to":{
                name: 'creativecentre.campaignDetail',
                params: {
                  category: _vm.category.id,
                  id: _vm.campaign.id
                }
              },"color":"primary","elevation":"1","fab":"","light":""}},[_c('v-icon',[_vm._v("fal fa-eye")])],1)],1):_vm._e()],1)],1),(_vm.campaign.isExpired)?_c('v-overlay',{staticClass:"chip-overlay",attrs:{"color":"white","opacity":"0.5","z-index":"1","absolute":""}},[_c('div',[_c('v-chip',{attrs:{"x-small":""}},[_vm._v("Expired")])],1)]):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }