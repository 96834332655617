import { render, staticRenderFns } from "./postPreview.vue?vue&type=template&id=1f438bea&scoped=true&"
import script from "./postPreview.vue?vue&type=script&lang=ts&"
export * from "./postPreview.vue?vue&type=script&lang=ts&"
import style0 from "./postPreview.vue?vue&type=style&index=0&id=1f438bea&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f438bea",
  null
  
)

export default component.exports