
import { Component, PropSync } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';

@Component({})
export default class CreatePostSuccessModal extends LoggedInComponentBase {
  @PropSync('open', { default: false, required: true }) isVisible!: boolean;
  public gotoCalender() {
    this.$router.push({ name: 'calendar.root' });
  }
  public close() {
    this.isVisible = false;
  }
}
