import { Component } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { AltDrawerType } from '@/constants';

@Component
export class SidebarMixin extends LoggedInComponentBase {
  private routeName = this.$route.name;

  mounted() {
    this.updateSidebarStates();
  }

  private closeMainNav() {
    /**
     * Vuetify will emit an `input` event whenever a navigation drawer changes state,
     * and this can interfere with our own commands, so use a timeout to wait a tick.
     */
    setTimeout(() => {
      if (this.uiModule.mainNavigationDrawerOpen) {
        this.uiModule.setNavigationDrawer(false);
      }
    });
  }

  public updateSidebarStates() {
    if (
      this.routeName?.toLowerCase().includes('helpandfaqs') ||
      this.routeName?.toLowerCase().includes('settings') ||
      this.routeName?.toLowerCase().includes('home')
    ) {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.closeMainNav();
      } else {
        this.uiModule.setNavigationDrawer(true);
      }
    } else if (this.routeName?.toLowerCase().includes('assetlibrary')) {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.uiModule.toggleAltDrawer({
          drawerType: AltDrawerType.CampaignSidebar,
          open: false
        });

        this.closeMainNav();
      } else {
        this.uiModule.toggleAltDrawer({
          drawerType: AltDrawerType.CampaignSidebar,
          open: true
        });
        this.uiModule.setNavigationDrawer(true);
      }
    } else if (
      this.routeName?.toLowerCase().includes('calendar') ||
      this.routeName?.toLowerCase().includes('creativecentre')
    ) {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.uiModule.toggleAltDrawer({
          drawerType: AltDrawerType.UpcomingPostsSidebar,
          open: false
        });

        this.closeMainNav();
      } else {
        this.uiModule.toggleAltDrawer({
          drawerType: AltDrawerType.UpcomingPostsSidebar,
          open: true
        });
        this.uiModule.setNavigationDrawer(true);
      }
    }
  }
}
