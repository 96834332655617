
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import {
  PostListItem,
  PostChannelSettings,
  MsoPostStoresDetails
} from '@/models/posts/postListItem';
import {
  PublishedPostLinksItem,
  PublishedPostsTableHeaders
} from '@/models/posts/postModal';
import { Component, Prop } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

@Component
export default class StoresTable extends LoggedInComponentBase {
  @Prop({ required: true }) post!: PostListItem;

  public publishedPostsTableHeaders: DataTableHeader[] = PublishedPostsTableHeaders;

  public get publishedPostsTableTitle(): string {
    if (this.post.msoPostStores) {
      const numStoresPostedTo = this.post.msoPostStores.length ?? 0;
      return `This post was posted for ${numStoresPostedTo} store${
        numStoresPostedTo > 1 ? 's' : ''
      }.`;
    } else {
      return `This post was posted for ${this.userModule.currentStore.title} in ${this.userModule.currentStore.state}`;
    }
  }

  public get getItems(): PublishedPostLinksItem[] {
    let items: PublishedPostLinksItem[] = [];
    if (this.post.msoPostStores) {
      // MSO
      items = this.post.msoPostStores.map((store: MsoPostStoresDetails) => {
        const item: PublishedPostLinksItem = {
          id: store.storeId,
          name: store.name,
          region: store.region
        };

        (store.postedChannels as any).forEach(
          ({ name, url }: { name: string; url: string }) => {
            item[`${name.toLowerCase()}Url`] = this.getPostUrl(
              store.storeId,
              name.toLocaleLowerCase(),
              url
            );
          }
        );

        return item;
      });
    } else {
      // SSO
      const { id, title, state } = this.userModule.currentStore;
      const item: PublishedPostLinksItem = {
        id,
        name: title,
        region: state
      };

      this.post.channelSettings.forEach((channel: PostChannelSettings) => {
        if (channel.channelType === 1) {
          item.facebookUrl = this.getPostUrl(id, 'facebook', channel.channelId);
        }
        if (channel.channelType === 2) {
          item.instagramUrl = this.getPostUrl(
            id,
            'instagram',
            channel.channelId
          );
        }
      });
      items.push(item);
    }
    return items;
  }

  private getPostUrl(storeId: string, platform: string, channelId: string) {
    return `${process.env.VUE_APP_API_ENDPOINT_PROXY}/api/stores/${storeId}/viewpost/${platform}/${channelId}`;
  }
}
