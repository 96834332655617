
import { Component } from 'vue-property-decorator';
import Notification from '@/components/notifications/notification.vue';
import { NotificationType } from '@/types/notificationType';
import { StoresServiceClient } from '@/api/clients/storesServiceClient';
import { StoresModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { SocialChannels } from '@/models/posts/socialChannels';

const storesModule = getModule(StoresModule);
const storesServiceClient = new StoresServiceClient();

@Component({
  components: { Notification },
})
export default class AuthoriseSocialChannelsModal extends LoggedInComponentBase {
  private modalIsLoading: boolean = false;
  public get dialog(): boolean {
    return this.storesModule.showAuthoriseSocialChannelsModal;
  }

  public set dialog(val: boolean) {
    this.storesModule.toggleAuthoriseSocialChannelsModal(val);
  }

  public get currentlyAuthorisedText(): string {
    const channelsNotAuthorised = SocialChannels.filter(
      (channel) => !this.storeChannels.includes(channel)
    );

    if (
      this.storeChannels.length > 0 &&
      this.storeChannels.length === SocialChannels.length
    ) {
      return `<p>Your store is currently authorised for: <strong>${this.storeChannels}</strong></p>`;
    } else if (
      this.storeChannels.length > 0 &&
      this.storeChannels.length < SocialChannels.length
    ) {
      return `<p>Your store is currently authorised for: <strong>${this.storeChannels}</strong></p><p>You are not yet authorised for: <strong>${channelsNotAuthorised}</strong>`;
    } else {
      return 'Your store has not authorised any social channels yet.';
    }
  }

  public get currentStore() {
    return this.userModule.currentStore;
  }

  private get notificationInstance(): NotificationType {
    return this.$refs.notification as NotificationType;
  }

  public get storeChannels(): string[] {
    return storesModule.storeChannels;
  }

  public get title(): string {
    return this.storeChannels.length < SocialChannels.length
      ? 'Not all of your social channel accounts have been authorised'
      : 'All social accounts are authorised';
  }

  public async authoriseSocialChannels() {
    this.modalIsLoading = true;
    // Get a link generated by Ayrshare that will let the user authenticate social channels for their store.
    const response = await storesServiceClient.getAdapterSSOLink();
    const ssoLink = response.data;
    const ssoWindow = window.open(ssoLink, '_blank');

    // Check at intervals if the Ayrshare window has been closed yet, if so we check their social channels again.
    const loop = setInterval(async () => {
      if (ssoWindow?.closed) {
        clearInterval(loop);
        // This will get the latest list of social channels that this store is authorised for.
        await storesModule.updateSocialChannels(this.currentStore!.id);
        this.modalIsLoading = false;

        if (this.storeChannels.length > 0) {
          this.notificationInstance.showSuccess(
            `These social channels are now authorised: ${this.storeChannels}`
          );

          setTimeout(() => {
            this.dialog = false;
          }, 4000);
        } else {
          this.notificationInstance.showError(
            'No social channels have been authorised yet, you need at least one to be able to post.'
          );
        }
      }
    }, 1000);
  }
}
